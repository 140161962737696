import React from 'react'
import vedio from '../Assets/images/NanbanBG.mp4';
import Insta from '../Assets/images/insta.svg';
import Twitter from '../Assets/images/twitter.png';
import facebook from '../Assets/images/facebook.svg';
import Logo from '../Assets/images/Logo.svg';
import Logonew from '../Assets/images/Vector.svg'
import Splitter from '../Assets/images/splitter.svg';
import Line from '../Assets/images/Line.png'

import FlimLabel from '../Assets/images/FlimLabel.png';
import GatewayLabel from '../Assets/images/GatewayLabel.png';
import EventLabel from '../Assets/images/EventLabel.png';
import MusicLabel from '../Assets/images/MusicLabel.png';
import WriterLabel from '../Assets/images/WriterLabel.png';
import AwardsLabel from '../Assets/images/AwardsLabel.png';


import Flim from '../Assets/images/Flim.svg';
import Event from '../Assets/images/Event.svg';
import Gateway from '../Assets/images/Gateway.svg';
import Music from '../Assets/images/Music.svg';
import Writer from '../Assets/images/Writer.svg';
import Awards from '../Assets/images/Awards.svg'; 

import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";

import { useState, useEffect } from 'react';




const bannerList = [
  {
      label: FlimLabel,
      img: Flim
  },
  {
      label: GatewayLabel,
      img: Gateway
  },
  {
      label: EventLabel,
      img: Event
  },
  {
      label: MusicLabel,
      img: Music
  },
  {
      label: WriterLabel,
      img: Writer
  },
  {
      label: AwardsLabel,
      img: Awards
  }
]

const Home = () => {
  const [currentIndex, setCurrentItemIndex] = useState(0);
   
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentItemIndex((prevIndex) =>
                prevIndex === bannerList.length - 1 ? 0 : prevIndex + 1
            );
        }, 2000);

        // Clear the timer to avoid memory leaks
        return () => { clearInterval(timer)};
    }, [bannerList.length]);

  return (
    <div className='video-background'>

    
      <div className=''>
        <video className='' autoPlay loop playsInline muted >
          <source src={vedio} type="video/mp4"></source>
        </video>
      </div>
    <div className='absolute inset-0 flex flex-col justify-center	'>

    <div className='flex mobile_logo_hidden logo_align lg:hidden'>
        <img src={Logo} className='xl:w-[420px] xl:h-[195px] shrink-0' alt=''></img>
    </div>

    <div className='grid 3xl:grid-cols-2 2xl:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-20 xl:pt-20 lg:px-10 pt-10 md:px-0'>

      <div className='flex items-center justify-center swiper__container'>
       <div className="xl:w-4/5 flex justify-center items-center ">
          <div className="flex justify-center items-center ">
             <Swiper
                spaceBetween={30}
                direction={'vertical'}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                  1024: {
                    slidesPerView: 1,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                  <div className='flex lg:flex-row xl:flex-row 2xl:flex-row 3xl:flex-row gap-5 xs:flex-col sm:flex-col md:flex-col justify-center items-center'>
                    <div className='w-[39px] h-[39px] xs:w-[39px] xs:h-[39px] sm:w-[62px] sm:h-[62px] xs:flex-wrap '>
                      <img src={Gateway} alt=''></img>
                    </div>
                    <div className='title sm:text-[20px]'>NANBAN TALENT GATEWAY</div>
                    {/* <img src={GatewayLabel} alt=''></img> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='flex lg:flex-row xl:flex-row 2xl:flex-row 3xl:flex-row gap-5 xs:flex-col sm:flex-col md:flex-col justify-center items-center'>
                    <div className='w-[39px] h-[39w-[39px] h-[39px] xs:w-[39px] xs:h-[39px] sm:w-[62px] sm:h-[62px] xs:flex-wrap '>
                     <img src={Flim} alt=''></img>
                    </div>
                   <div className='title'>FILM PRODUCTION</div>
                   {/* <img src={FlimLabel} alt=''></img> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='flex lg:flex-row xl:flex-row 2xl:flex-row 3xl:flex-row gap-5 xs:flex-col sm:flex-col md:flex-col justify-center items-center'>
                    <div className='w-[39px] h-[39px] xs:w-[39px] xs:h-[39px] sm:w-[62px] sm:h-[62px] xs:flex-wrap '>
                  <img src={Event} alt=''></img>
                    </div>
                  <div className='title'>EVENTS</div>
                  {/* <img src={EventLabel} alt=''></img> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='flex lg:flex-row xl:flex-row 2xl:flex-row 3xl:flex-row gap-5 xs:flex-col sm:flex-col md:flex-col justify-center items-center'>
                    <div className='w-[39px] h-[39px] xs:w-[39px] xs:h-[39px] sm:w-[62px] sm:h-[62px] xs:flex-wrap '>
                  <img src={Music} alt=''></img>
                    </div>
                  <div className='title'>MUSIC</div>
                  {/* <img src={MusicLabel} alt=''></img> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='flex lg:flex-row xl:flex-row 2xl:flex-row 3xl:flex-row gap-5 xs:flex-col sm:flex-col md:flex-col justify-center items-center'>
                    <div className='w-[39px] h-[39px] xs:w-[39px] xs:h-[39px] sm:w-[62px] sm:h-[62px] xs:flex-wrap '>
                  <img src={Writer} alt=''></img>
                    </div>
                  <div className='title'>WRITER’S HUB</div>
                  {/* <img src={WriterLabel} alt=''></img> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='flex lg:flex-row xl:flex-row 2xl:flex-row 3xl:flex-row gap-5 xs:flex-col sm:flex-col md:flex-col justify-center items-center'>
                    <div className='w-[39px] h-[39px] xs:w-[39px] xs:h-[39px] sm:w-[62px] sm:h-[62px] xs:flex-wrap '>
                  <img src={Awards} alt=''></img>
                    </div>
                  <div className='title'>NANBAN AWARDS</div>
                  {/* <img src={AwardsLabel} alt=''></img> */}
                  </div>
                </SwiperSlide>
                
            </Swiper>
            </div>
        </div>

      <div className='w-1/5 flex justify-end h-[500px]'>
        <img src={Line} className='splitter_hidden' alt=''></img>
      </div>  
    </div>


      <div className='flex flex-col lg:justify-center items-center gap-5 form__containter'>
      <div className='logo_hidden -mb-[35px]'>
        <img src={Logonew} className='2xl:w-[420px] 2xl:h-[195px] w-[320px] h-[100px] shrink-0' alt=''></img>
      </div>

      <div className=' flex flex-col justify-center items-center text-white '>
        <p className='font-light xl:text-[40px] text-[30px]'>Stay Tuned!</p>
      </div>

      <div className=' flex justify-center items-center placeholder-{rgba(0, 0, 0, 0.3)} xl:mt-[5px] '>
        <form className='flex xl:flex-row flex-col gap-5 xs:flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap' method="post" action="https://nanbanentertainment.com/contact.php">
          <input type='email' name='email' placeholder='name@email.com' required className='w-[250px] lg:w-[270px] md:w-[250px] p-3 rounded-lg text-lg bg-[#ebebeb] text-black'/>
          <input type='submit' value="Get Notified!" className='cursor-pointer bg-primary rounded-lg text-xl text-white p-4'/>
        </form>
      </div>
    </div>
  
    </div>

    <footer className='flex items-center flex-col justify-start xl:gap-10 gap-5 mb-10 lg:mt-10 md:mt-0 footer'>
        <div className='flex flex-row gap-10 items-center'>
          <a target="_blank" rel='noreferrer' href ={'https://twitter.com/NanbanEntIndia'} className='footer__link'>  
            <img src={Twitter} className='shrink-0 xl:w-[35px] xl:h-[30px] lg:w-[25px] lg:h-[20px]' alt="nanban-entertainment" />
            </a>
            <a target="_blank" rel='noreferrer' href ={'https://www.instagram.com/nanbanentindia/'} className='footer__link'> 
            <img src={Insta} className='shrink-0 xl:w-[35px] xl:h-[30px] lg:w-[25px] lg:h-[20px]' alt="nanban-entertainment"/>
            </a> 
            <a target="_blank" rel='noreferrer' href ={'https://www.facebook.com/NanbanEntIndia'} className='footer__link'>  
            <img src={facebook} className='shrink-0 xl:w-[35px] xl:h-[30px] lg:w-[25px] lg:h-[20px]' alt="nanban-entertainment" />
          </a>
        </div>

        <div className='flex items-center justify-start xs:justify-center text-white text-xl xs:text-[15px] font-normal xs:flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap'>
          <p>NANBAN ENTERTAINMENT INDIA PVT LTD <span className='divider'>|</span> </p>
          <p> ©2023 ALL RIGHTS RESERVED</p>
        </div>

      </footer>
      
      </div>
    </div>
  )
}

export default Home